import { useState } from "react";
import "./Project.scss";

import { Header2 } from "./Header2/Header2";
import { Row, Col } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import YoutubeEmbed from "./YoutubeEmbed.js";
import "./YoutubeEmbed.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const Project = (props) => {
  const { defaultPdfFile, mobilePdfFile, video } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  //create new plugin instance
  return (
    <>
      <Header2 />
      <center>
        <Row className="pdf-container desktop">
          <Col span={24}>
            {video && <YoutubeEmbed embedId="rsSpLbzOezw" />}
            <Document
              file={defaultPdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  renderMode="canvas"
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </Col>
        </Row>
        <Row className="pdf-container mobile">
          <Col span={24}>
            {video && <YoutubeEmbed embedId="rsSpLbzOezw" />}
            <Document
              file={mobilePdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  renderMode="canvas"
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </Col>
        </Row>
      </center>
    </>
  );
};

export default Project;
